import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { visible: Boolean }

  display() {
    this.element.style.animation = "fade-in-and-out 4s"
    this.visibleValue = true
    setTimeout(() => { this.visibleValue = false }, 4000)
  }
}
