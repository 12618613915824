import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option"]

  hideOptions() {
    this.optionTargets.forEach((option) => option.dataset.visible = false)
  }

  // The target optional form element must have an id equal to the value of the element triggering the showField action
  showField(event) {
    this.hideOptions()
    const target = document.getElementById(event.target.value)
    if (target) {
      target.dataset.visible = true
    }
  }
}
