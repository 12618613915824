import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "errorMessage", "textarea" ]
  static values = { maxRows: Number, emails: Array }

  check(event) {
    const rows = this.textareaTarget.value.split("\n")

    if (rows.length > this.maxRowsValue) {
      this.textareaTarget.value = rows.slice(0, this.maxRowsValue).join("\n")
      this.errorMessageTarget.dataset.visible = "true"
    } else {
      this.errorMessageTarget.dataset.visible = "false"
    }
  }

  emailsValueChanged() {
    if (this.emailsValue.length > 0) {
      this.textareaTarget.value = this.emailsValue.join("\n")
    }
  }
}
