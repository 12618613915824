// Example: Load Rails libraries in Vite.
//
import * as Turbo from '@hotwired/turbo'
Turbo.session.drive = false

//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import '~/controllers'

// table-saw web component for mobile tables: https://github.com/zachleat/table-saw?tab=readme-ov-file
import '@zachleat/table-saw'
