import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  enable(event) {
    mwDark("themePreviewEvent", "enableCustomTheme", {checked: event.target.checked});
    mwLight("themePreviewEvent", "enableCustomTheme", {checked: event.target.checked});
  }

  update(event) {
    let value = event.target.value
    let type = event.target.dataset.type

    if (value.length === 7 && value[0] === "#") {
      mwDark("themePreviewEvent", "changeColor", {type: type, value: value});
      mwLight("themePreviewEvent", "changeColor", {type: type, value: value});
    }
  }
}
