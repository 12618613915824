import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // This controller maintains the scroll position of the target element
  static targets = [ "scrollContainer" ]
  static values = { scrollPosition: Number, sessionStorage: String }

  connect() {
    this.scrollPositionValue = sessionStorage.getItem(this.sessionStorageValue)
    if (this.scrollPositionValue !== null) {
      this.scrollContainerTarget.scrollTop = this.scrollPositionValue
    }
  }

  saveScrollPosition() {
    sessionStorage.setItem(this.sessionStorageValue, this.scrollContainerTarget.scrollTop);
  }
}
