import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "copyButton", "copiedIndicator"]
  static outlets = ["toast"]
  static values = { copied: Boolean }

  copy(event) {
    event.preventDefault()

    navigator.clipboard.writeText(this.sourceTarget.innerHTML)

    this.toggleCopied()
    setTimeout(() => { this.toggleCopied() }, 2000)
  }

  copyLink(event) {
    event.preventDefault()

    navigator.clipboard.writeText(this.sourceTarget.href)

    this.toastOutlet.display()
  }

  toggleCopied() {
    this.copiedValue = !this.copiedValue
    this.toggleTargets()
  }

  toggleTargets() {
    this.copyButtonTarget.dataset.visible = !this.copiedValue
    this.copiedIndicatorTarget.dataset.visible = this.copiedValue
  }
}
