import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // This is a generic toggle controller that can be used in many situations
  // Styling can be based on the value using [data-toggle-target-value="false"] or [data-toggle-target-value="true"]
  static values = { target: Boolean }

  toggle() {
    this.targetValue = !this.targetValue
  }
}
