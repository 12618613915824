import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]
  static values = { open: Boolean }

  toggle() {
    this.closeOtherElements();

    this.openValue = !this.openValue
  }

  openValueChanged() {
    this.buttonTarget.setAttribute("aria-expanded", this.openValue)
  }

  closeOtherElements() {
    const otherControllers = document.querySelectorAll(`[data-controller='${this.identifier}']`);

    otherControllers.forEach(controllerElement => {
      if (controllerElement !== this.element) {
        const controllerInstance = this.application.getControllerForElementAndIdentifier(controllerElement, this.identifier);

        if (controllerInstance && controllerInstance.openValue) {
          controllerInstance.openValue = !controllerInstance.openValue;
        }
      }
    });
  }
}
