import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // This controller ensures the target item is not shown after a button is pressed (triggering the future method)
  static values = { visible: {type: Boolean, default: false}, name: String }

  connect() {
    let notice = localStorage.getItem(this.nameValue)
    if (notice !== "seen") {
      this.visibleValue = true
    }
  }

  nowAndFuture() {
    this.visibleValue = false
    this.future()
  }

  future() {
    localStorage.setItem(this.nameValue, "seen");
  }
}
