import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['colorWheel', 'colorInput']

  updateWheel(event) {
    if(event.target.value.length === 7 && event.target.value[0] === "#") {
      this.colorWheelTarget.value = event.target.value;
    }
  }

  updateInput(event) {
    this.colorInputTarget.value = event.target.value;
  }
}
