import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "slide"]
  static values = { visible: Boolean }

  visible() {
    this.visibleValue = !this.visibleValue;
  }

  visibleValueChanged() {
    this.slideTarget.dataset.visible = this.visibleValue;
    this.buttonTarget.setAttribute("aria-expanded", this.visibleValue);
    this.setContentHeight();
  }

  setContentHeight() {
    const height = this.visibleValue ? this.slideTarget.scrollHeight + "px" : 0;
    this.slideTarget.style.setProperty("--_accordion-content-height", height);
  }
}
