import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // This controller clicks the tab in the Registrations and Viewers table to move from the report part
  // of the page to the detailed information in the tables on mobile (as defined by bootstrap).
  // This is needed as there are no urls to go directly to the tables.
  static targets = [ "tabToOpen" ]
  static values = { mobileBreakpoint: { type: Number, default: 768 } }

  connect() {
    if (window.screen.width < this.mobileBreakpointValue) {
      this.tabToOpenTarget.click();
    }
  }
}
